
import { defineComponent } from 'vue';
import Logo from '@/layout/header/partials/MainLogo.vue';
import Footer from '@/layout/footer/FooterCenter.vue';

export default defineComponent({
  name: 'verify-account-page',
  components: {
    Logo,
    Footer,
  },
});
